// AppMain.js
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid2, Grow, Link, Typography,  } from '@mui/material';
import { styled } from '@mui/material/styles';
import VzmConceptMove from '../concept/VzmConceptMove';
import { useRecoilState } from "recoil";
import { usePolyglot } from '../../api/vzm-hook';
import { HomeText, languageAtom, UserLanguage } from '../../value/AppModel';
import LogoFootPrint from '../../asset/logo-foot-print.svg';
import SvgVzmAimes from '../../asset/vzm_aims.svg';
import ArchitectureRda from '../../asset/architecture_rda.svg';
import ArchitectureJdbc from '../../asset/architecture_jdbc.svg';
import SvgVision from '../../asset/vzm_vision.svg';
import TopRightBox from './TopRightBox';
import LangSelector from './LangSelector';

const PadTopAndBottom = '10px 0 10px 0';

const MainBox = styled(Box)({
    fontSize: '0.9rem', color: '#ccc', display: 'flex', flexDirection: 'column', alignItems: 'center',
});

const MidWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
    width: '95%',
    maxWidth: 1200,
});

const TitleBox = styled(Box)({
    alignSelf:'stretch', fontSize:'1.4rem', fontWeight:'bold', color:'#aaD', padding: '22px 0 20px 0', // border: '1px solid #334', borderRadius: 30,
    // gradient background (top to bottom)
    background: 'linear-gradient(0deg, #000032 0%, #000028 10%, #001 50%, #000028 80%, #000032 100%)',
    textAlign: 'center', transition: 'color 0.5s',
    '&:hover': {color:'#79f'},
});
const BoxWrapper = styled(Box)({alignSelf:'stretch', display:'flex', flexDirection:'column', alignItems:'center', padding:10});
const TextBox = styled(Box)({
    padding:10,
    '& li': {
        margin: '5px 0 5px 0',
        '&:hover': {color:'#79f'},
    },
    '& a': {
        color: '#9af',
        '&:hover': {color:'#ddf'},
    },
});
const ImageBox = styled(Box)({margin:10, display:'flex', justifyContent:'center', alignItems:'center', alignSelf:'stretch'});

const TextAndImage = styled(Box)({
    display: 'flex', alignItems: 'start', padding: PadTopAndBottom,
});
const ImageLeft = styled(Box)({flexGrow:1, position:'relative'});
const SideText = styled(Box)({padding:10});
const BigPhrase = styled(Box)({
    fontSize:'1.1rem', fontWeight:'bold', padding:PadTopAndBottom, cursor:'pointer', color: '#aaa',
    '&:hover': {color:'#79f'},
});
const BubbleBox = styled(Box)({
    border: '1px solid #558', borderRadius: 3, padding: '3px 12px 3px 12px',
    backgroundColor: '#224', fontSize: '0.9rem', color: '#ccf',
    position: 'absolute', maxWidth: 420, lineHeight: '1.2rem',
});

const ZappyBox = styled(Box)({
    pattern: '10px 0 15px 0', textAlign: 'center', fontSize: '1.6rem',  color: '#358', fontStyle: 'italic', cursor: 'default',
    boxSizing: 'border-box', paddingBottom: 15,
});
const ZappySpan = styled('span')({
    display: 'inline-block', padding: '2px', transition: 'color 0.5s',
    border: '1px solid transparent', borderRadius: 3,
    '&:hover': {color: '#79f', border: '1px solid #79f', backgroundColor: '#000048', paddingLeft: 3, paddingRight: 3},
});

const TypoH3 = styled(Typography)({
    shadow: '3px 3px 5px #ff0', color:'#aac', transition: 'color 0.5s',
    '&:hover': {color:'#79f'},
});

const TempMsgBox = styled(Box)({
    height:'80vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center',
    color: '#346',
    '&:hover': {color:'#458'},
});

/* const LanguageSelectorBox = styled(Box)({
    position: 'fixed', top: 100, right: 3, zIndex: 10000,
    border: '1px solid #334',
});
const FlagBox = styled(Box)({margin:1, border: '1px solid transparent'}); */

const ZappyPhrase = "The Very Basement of Your Zappy Coding Life".split(''); // 40 characters.

// This is a private service for Labisfun, Inc. You can freely use this service, though.

export default function AppMain() {
    //const [language, setLanguage] = useRecoilState(languageAtom);
    //const [langArray, setLangArray] = useState([]);
    const [scrollY, setScrollY] = useState(0);
    const polyglot = usePolyglot();
    const [aim, setAim] = useState({key:null});

    const onPageScroll = () => {
        setScrollY(window.scrollY || document.documentElement.scrollTop);
    };

    useEffect(() => {
        window.addEventListener('scroll', onPageScroll);
        return () => {
            window.removeEventListener('scroll', onPageScroll);
        };
    }, []);

    /* useEffect(()=>{
        setLangArray(Object.values(UserLanguage));
    }, []); */

    const renderZappy = () => {
        return ZappyPhrase.map((char, index) => {
            if(char === ' ') return <span key={index}>&nbsp;</span>;
            return <ZappySpan key={index}>{char}</ZappySpan>;
        });
    };

    /* const onChangeLanguage = (lang) => {
        setLanguage({key:lang.key, label: lang.label});
    }; */

    return (
        <MainBox>
            <MidWrapper>
                <TempMsgBox>
                    <p style={{fontSize: '3rem'}}>This is a private service for Labisfun, Inc.</p>
                    <p style={{fontSize: '2.4rem'}}>You can freely use this service, though.</p>
                    <p style={{fontSize: '1.8rem'}}>...yet...</p>
                </TempMsgBox>
                {/* <Box>
                    <Box style={{
                            height:'85vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center',
                            minHeight: 460,
                        }}
                    >
                        <TextAndImage style={{marginTop:20}}>
                            <ImageLeft>
                                <img src={SvgVzmAimes} alt="What Vzm Aims" width="100%"/>
                                <Grow in={aim.key===HomeText.VzmAims.aims.practicality.key}>
                                    <BubbleBox style={{...HomeText.VzmAims.aims.practicality.position}}>
                                        {polyglot(HomeText.VzmAims.aims.practicality.say)}
                                    </BubbleBox>
                                </Grow>
                                <Grow in={aim.key===HomeText.VzmAims.aims.bigpicture.key}>
                                    <BubbleBox style={{...HomeText.VzmAims.aims.bigpicture.position}}>
                                        {polyglot(HomeText.VzmAims.aims.bigpicture.say)}
                                    </BubbleBox>
                                </Grow>
                                <Grow in={aim.key===HomeText.VzmAims.aims.teamwork.key}>
                                    <BubbleBox style={{...HomeText.VzmAims.aims.teamwork.position}}>
                                        {polyglot(HomeText.VzmAims.aims.teamwork.say)}
                                    </BubbleBox>
                                </Grow>
                                <ZappyBox>
                                    {renderZappy()}
                                </ZappyBox>
                            </ImageLeft>
                            <SideText width={300} style={{marginBottom:40}}>
                                <TypoH3 variant="h3" gutterBottom style={{textAlign:'right'}}>
                                    Vision of VZM
                                </TypoH3>
                                <BigPhrase
                                    onMouseEnter={()=>setAim(HomeText.VzmAims.aims.practicality)}
                                    onMouseLeave={()=>setAim({})}
                                >
                                    - {polyglot(HomeText.VzmAims.aims.practicality.title)}
                                </BigPhrase>
                                <BigPhrase 
                                    onMouseEnter={()=>setAim(HomeText.VzmAims.aims.bigpicture)}
                                    onMouseLeave={()=>setAim({})}
                                >
                                    - {polyglot(HomeText.VzmAims.aims.bigpicture.title)}
                                </BigPhrase>
                                <BigPhrase
                                    onMouseEnter={()=>setAim(HomeText.VzmAims.aims.teamwork)}
                                    onMouseLeave={()=>setAim({})}
                                >
                                    - {polyglot(HomeText.VzmAims.aims.teamwork.title)}
                                </BigPhrase>
                            </SideText>
                        </TextAndImage>
                    </Box>
                    <TitleBox>{polyglot(HomeText.whatDoesVzmDo.title)}</TitleBox>
                    <BoxWrapper>
                        <TextBox>{polyglot(HomeText.whatDoesVzmDo.say)}</TextBox>
                    </BoxWrapper>
                    <ImageBox>
                        <VzmConceptMove />
                    </ImageBox>
                    <TitleBox style={{marginTop:50}}>
                        {polyglot(HomeText.whatDoYouGet.title)}
                    </TitleBox>
                    <BoxWrapper>
                    {
                        HomeText.whatDoYouGet.lines.map((ben, index) => {
                            return(
                                <Box key={index} style={{display:'flex', width:'90%'}}>
                                    <img src={ben.image} alt={ben.title} width={50} style={{marginRight:10}}/>
                                    <Box style={{flexGrow:1}}>
                                        <TextBox>{polyglot(ben.say)}</TextBox>
                                    </Box>
                                </Box>
                            );
                        })
                    }
                    </BoxWrapper>
                    <TitleBox style={{marginTop:50}}>
                        {polyglot(HomeText.whatDoIDo.title)}
                    </TitleBox>
                    <BoxWrapper>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={6}>
                                <img src={ArchitectureRda} alt="Using RDA" width="100%"/>
                            </Grid2>
                            <Grid2 xs={6}>
                                <img src={ArchitectureJdbc} alt="Using JDBC" width="100%"/>
                            </Grid2>
                        </Grid2>
                    </BoxWrapper>
                    <BoxWrapper>
                        <TextBox>{polyglot(HomeText.whatDoIDo.say)}</TextBox>
                    </BoxWrapper>
                </Box>
                <Box style={{padding:8, backgroundColor:'#6576FA', color:'#000', borderRadius:20, margin:20, alignSelf:'stretch'}}>
                    <Link href="/vzm" style={{color:'#fff', fontSize:'1.2rem', fontWeight:'bold', textDecoration:'none',
                        display:'flex', alignItems:'center', justifyContent:'center', 
                    }}>
                        <img src={LogoFootPrint} alt="VZM" width={24} style={{marginRight:20, marginLeft:20}} />
                        {polyglot({
                            korean: '이제 VZM 서비스를 시작해 보세요.',
                            english: 'Try VZM Service Now.',
                            spanish: 'Prueba el servicio VZM ahora.',
                        })}
                    </Link>
                </Box> */}
            </MidWrapper>
            <Grow in={scrollY > 100}>
                <Box style={{position:'fixed', top:0, right:0, height:36, width:"100vw", backgroundColor:'#000032'}}>3&nbsp;</Box>
            </Grow>
            <TopRightBox open={scrollY > 100} />
            <LangSelector />
        </MainBox>
    );
}

